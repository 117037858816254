import cls from './Modal.module.css';
import { ReactComponent as CloseBtn } from '../../assets/icons/close-btn.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/check.svg';
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg';

const Modal = ({
  onClose,
  title,
  baseWidth,
  children,
  subtitle,
  isError = false,
  isSuccess = false,
}) => {
  return (
    <div className={cls.wrapper}>
      <div
        className={cls.inner}
        style={{ width: baseWidth }}
      >
        <div className={cls.header}>
          <h3
            className={[cls.title, isSuccess ? cls.success : '', isError ? cls.error : ''].join(
              ' ',
            )}
          >
            {isSuccess && <SuccessIcon />}
            {isError && <ErrorIcon />}
            {title}
          </h3>
          {subtitle && <div className={cls.subtitle}>{subtitle}</div>}
          <div
            className={cls.close}
            onClick={onClose}
          >
            <CloseBtn />
          </div>
        </div>
        <div className={cls.contentWrap}>
          <div className={cls.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
