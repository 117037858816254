import VacancyItem from '../../components/VacancyItem';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import '../../App.css';
import { services } from '../../data/serviceDetail';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Helmet } from 'react-helmet';

const ServiceDetailPage = () => {
  const { pid } = useParams();
  const service = services.find((el) => el.id === +pid);
  if (!service) {
    return (
      <>
        <Header />
        <main>
          <div className="container">Не найдено</div>
        </main>
        <Footer />
      </>
    );
  }
  return (
    <>
      <Header />
      <main>
        <div className="container">
          <Helmet>
            <title>{service.title}</title>
          </Helmet>
          <Breadcrumbs
            last={service.title}
            list={[
              {
                href: '/',
                label: 'Главная',
              },
              {
                href: '/services',
                label: 'Услуги',
              },
            ]}
          />
        </div>
        <div className="container">
          <h1 className="h1">{service.title}</h1>
        </div>
        <div className="service">
          <div className="container">
            <section className="service__description">{service.description}</section>
            <section className="service-characteristic">
              <h2 className="service-characteristic__title">
                Основные характеристики и преимущества услуги
              </h2>
              <div className="service__body">
                <ul className="service-characteristic__list">
                  {service.charact.map((char, index) => {
                    return (
                      <li
                        className="service-characteristic__item"
                        key={index}
                      >
                        <h6 className="service-characteristic__item-title">{char.title}</h6>
                        <div className="service-characteristic__item-text">{char.description}</div>
                      </li>
                    );
                  })}
                </ul>
                <img
                  alt="service"
                  src="/img/service.png"
                  className="service__img"
                />
              </div>
            </section>

            <div className="service__text">{service.text}</div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default ServiceDetailPage;
