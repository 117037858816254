import { links } from '../data/links';
import { Link } from 'react-router-dom';
import MenuModal from './MenuModal';
import MobileMenu from './MobileMenu';
import { useEffect, useState } from 'react';
import { OrderModal } from './OrderModal/OrderModal';
import { useLockScroll } from '../hooks/useLockScroll';

const Header = ({ isMain = false }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const { lock, unlock } = useLockScroll();
  useEffect(() => {
    if (isModalOpen) {
      lock();
    } else {
      unlock();
    }
  }, [isModalOpen]);

  return (
    <>
      <header className={['header', isMain ? 'main' : ''].join(' ')}>
        <div className="container">
          <div className="header__left">
            <Link
              to="/"
              className="header__logo"
            >
              <img
                src="/img/logo.svg"
                alt=""
              />
            </Link>
            <ul className="header__menu">
              {links.map((link) => {
                return (
                  <li key={link.label}>
                    <Link to={link.href}>{link.label}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="header__right">
            <a
              href="tel:+74952408156"
              className="header__right-item header__phone"
            >
              + 7(495)240-81-56
            </a>
            <a
              href="mailto:manager@visint.online"
              className="header__right-item header__mail"
            >
              manager@visint.online
            </a>
          </div>
          <button
            className="header__order-btn"
            onClick={() => setModalOpen(true)}
          >
            Заказать
          </button>
        </div>
      </header>
      <MenuModal
        isOpen={isMenuOpen}
        onClose={() => setMenuOpen(false)}
      />
      <MobileMenu
        showMenu={() => setMenuOpen(true)}
        showModal={() => setModalOpen(true)}
      />
      {isModalOpen && (
        <OrderModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

export default Header;
