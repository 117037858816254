import Footer from '../components/Footer';
import Header from '../components/Header';
import '../App.css';
import Breadcrumbs from '../components/Breadcrumbs';
import { Helmet } from 'react-helmet';

const Portfolio = () => {
  return (
    <>
      <Header />
      <main>
        <Helmet>
          <title>Реализованные проекты</title>
        </Helmet>
        <div className="container">
          <Breadcrumbs
            last="Реализованные проекты"
            list={[
              {
                href: '/',
                label: 'Главная',
              },
            ]}
          />
        </div>
        <div className="container">
          <h1 className="h1">Реализованные проекты</h1>
        </div>
        <section className="portfolio">
          <div className="container">
            <table className="table">
              <thead>
                <tr>
                  <th>Заказчик</th>
                  <th>Описание</th>
                  <th>Оборудование</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="customer">
                      <img
                        alt="customer"
                        src="../img/madi.svg"
                      />
                      ФГБУ ВО "МАДИ"
                    </div>
                  </td>
                  <td>Поставка оборудования для ЦАДИ</td>
                  <td>Микроскопы оптические  Компьютеры, их части и принадлежности</td>
                </tr>
                <tr>
                  <td>
                    <div className="customer">
                      <img
                        alt="customer"
                        src="../img/madi.svg"
                      />
                      ФГБУ ВО "МАДИ"
                    </div>
                  </td>
                  <td>Поставка автоматизированных рабочих мест </td>
                  <td>Устройства автоматической обработки данных</td>
                </tr>
              </tbody>
            </table>
            <div className="table-mobile">
              <div className="table-item">
                <div className="table-row">
                  <div className="table-head">Заказчик</div>
                  <div>
                    <div className="customer">
                      <img
                        alt="customer"
                        src="../img/madi.svg"
                      />
                      ФГБУ ВО "МАДИ"
                    </div>
                  </div>
                </div>
                <div className="table-row">
                  <div className="table-head">Описание</div>
                  <div>Поставка оборудования для ЦАДИ</div>
                </div>
                <div className="table-row">
                  <div className="table-head">Оборудование</div>
                  <div>Микроскопы оптические  Компьютеры, их части и принадлежности</div>
                </div>
              </div>
              <div className="table-item">
                <div className="table-row">
                  <div className="table-head">Заказчик</div>
                  <div>
                    <div className="customer">
                      <img
                        alt="customer"
                        src="../img/madi.svg"
                      />
                      ФГБУ ВО "МАДИ"
                    </div>
                  </div>
                </div>
                <div className="table-row">
                  <div className="table-head">Описание</div>
                  <div>Поставка автоматизированных рабочих мест</div>
                </div>
                <div className="table-row">
                  <div className="table-head">Оборудование</div>
                  <div>Устройства автоматической обработки данных</div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Portfolio;
