import Footer from '../components/Footer';
import Header from '../components/Header';
import '../App.css';
import Breadcrumbs from '../components/Breadcrumbs';
import { Helmet } from 'react-helmet';
const About = () => {
  return (
    <>
      <Header />
      <main>
        <Helmet>
          <title>О компании</title>
        </Helmet>
        <div className="section">
          <div className="container">
            <Breadcrumbs
              last="О компании"
              list={[
                {
                  href: '/',
                  label: 'Главная',
                },
              ]}
            />
          </div>
          <div className="container">
            <h1 className="h1">О компании</h1>
            <p className="p_line">
              Наша компания занимается поставкой высококачественного IT-оборудования от лучших
              российских производителей.
            </p>
            <p className="p_line">
              Мы предлагаем широкий ассортимент товаров, включая компьютеры, ноутбуки, планшеты,
              мониторы, сервера, сетевое оборудование и многое другое.
            </p>
            <p className="p_line">
              Мы гордимся, что реализуемая в наших проектах продукция производится и разрабатывается
              на территории России, что гарантирует высокое качество и надежность продукции, а также
              снижает затраты на логистику.
            </p>
            <p className="p_line">
              Наша команда состоит из опытных специалистов, которые всегда готовы помочь нашим
              клиентам в выборе наиболее подходящего оборудования.
            </p>
            <p className="p_line">
              Мы предлагаем гибкую систему ценообразования и быструю доставку, чтобы обеспечить
              максимально комфортное и удобное сотрудничество. Мы стремимся к тому, чтобы наши
              клиенты получили максимальную отдачу от использования IT-оборудования, которое мы
              предлагаем.
            </p>
            <p className="border_line">
              Наша миссия - обеспечить надежность и качество продукции, а также предоставить нашим
              клиентам высококлассный сервис и техническую поддержку.
            </p>
            <p className="p_line">
              Если вы ищете надежного партнера для поставок IT-оборудования, то мы готовы стать
              таким партнером для вас! Свяжитесь с нами и мы с радостью ответим на все ваши вопросы.
            </p>
          </div>
        </div>
        <div className="section">
          <div className="container">
            <h3 className="h3">Наши реквизиты</h3>
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td>Полное наименование</td>
                  <td>Общество с ограниченной ответственностью «ВИСМУТ ИНТЕГРА»</td>
                </tr>
                <tr>
                  <td>Сокращенное наименование</td>
                  <td>ООО «ВИСМУТ ИНТЕГРА»</td>
                </tr>
                <tr>
                  <td>Юридический адрес</td>
                  <td>115211, г.Москва, ул. Борисовские пруды, д. 6, к. 1, ЭТ/ПОМ/КОМН 1/I/7</td>
                </tr>
                <tr>
                  <td>Почтовый адрес</td>
                  <td>115211, г.Москва, ул. Борисовские пруды, д. 6, к. 1, ЭТ/ПОМ/КОМН 1/I/7</td>
                </tr>
                <tr>
                  <td>Фактический адрес</td>
                  <td>115211, г.Москва, ул. Борисовские пруды, д. 6, к. 1, ЭТ/ПОМ/КОМН 1/I/7</td>
                </tr>
                <tr>
                  <td>Телефон/факс</td>
                  <td>+7 925 775 88 18</td>
                </tr>
                <tr>
                  <td>ИНН/КПП</td>
                  <td>9724060018/772401001</td>
                </tr>
                <tr>
                  <td>ОГРН</td>
                  <td>1217700489929</td>
                </tr>
                <tr>
                  <td>Расчётный счет</td>
                  <td>40702810801500109285</td>
                </tr>
                <tr>
                  <td>Корреспондентский счет</td>
                  <td>30101810845250000999</td>
                </tr>
                <tr>
                  <td>БИК банка</td>
                  <td>044525999</td>
                </tr>
                <tr>
                  <td>Банк</td>
                  <td>ТОЧКА ПАО БАНКА "ФК ОТКРЫТИЕ"</td>
                </tr>
                <tr>
                  <td>Генеральный директор</td>
                  <td>Монин Андрей Евгеньевич, действующий на основании Устава</td>
                </tr>
                <tr>
                  <td>E-mail:</td>
                  <td>manager@visint.online</td>
                </tr>
              </tbody>
            </table>
            <p className="line_smtext">Предприятие находится на ОСН с НДС.</p>
          </div>
        </div>
        <div className="section contact">
          <div className="container">
            <h3 className="h3">Где мы находимся</h3>
            <div className="contact-info">
              <div className="contact-info__item">
                <div className="contact-info__item-name">Адрес:</div>
                <div className="contact-info__item-desc">
                  г.Москва, ул. Борисовские пруды, д. 6, к. 1, ЭТ/ПОМ/КОМН 1/I/7
                </div>
              </div>
              <div className="contact-info__item">
                <div className="contact-info__item-name">Телефон:</div>
                <div className="contact-info__item-desc">
                  <a
                    href="tel:+74952408156"
                    className="contact-info__item-desc--link"
                  >
                    + 7(495)240-81-56
                  </a>
                </div>
              </div>
            </div>
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A508925c07259c080554cc4304793e911d75779ffcd06ad26ccb586c73daa1d7f&amp;source=constructor"
              width="100%"
              height="480"
              frameBorder="0"
            />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default About;
