import cls from './OrderModal.module.css';
import { useAppForm } from '../../hooks/useAppForm';
import Modal from '../Modal/Modal';
import Input from '../Input/Input';
import { useState } from 'react';
import { isValidateEmail } from '../../utils';

const OrderModal = ({ onClose }) => {
  const [isSuccess, setSuccess] = useState(false);
  const [isError, setError] = useState(false);
  const onSubmit = async ({ email, name, phone }) => {
    console.log(name, email, phone);
    const form = new FormData();
    form.append('email', email);
    form.append('name', name);
    form.append('phone', phone);
    try {
      await axios.post('../mailhandler.php', formData);
      setSuccess(true);
    } catch (e) {
      setError(true);
    }
  };

  const reset = () => {
    formik.resetForm();
    setSuccess(false);
    setError(false);
  };

  const validate = (values) => {
    console.log('validate');
    const errors = {};
    if (!values.name) {
      errors.name = 'Заполните поле';
    }
    if (!values.email || !isValidateEmail(values.email)) {
      errors.email = 'Введите корректный email';
    }
    if (!values.phone || values.phone.length < 11) {
      errors.phone = 'Введите корректный номер';
    }
    console.log(errors);
    return errors;
  };

  const formik = useAppForm({ email: '', name: '', phone: '' }, onSubmit, validate);

  if (isSuccess) {
    return (
      <Modal
        title="Ваша заявка принята"
        subtitle=""
        isSuccess
        baseWidth={540}
        imgSrc=""
        onClose={onClose}
      >
        <div className={cls.responseText}>
          Ваша заявка принята, мы свяжемся с вами в ближайшее время
        </div>
      </Modal>
    );
  }

  if (isError) {
    return (
      <Modal
        title="Ошибка"
        subtitle=""
        isError
        baseWidth={540}
        imgSrc=""
        onClose={onClose}
      >
        <div className={cls.responseText}>Что-то пошло не так. Пожалуйста, повторите попытку</div>
        <div className="form-response-btn">
          <button
            className="btn-primary"
            onClick={reset}
          >
            Повторить попытку
          </button>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      title="Оставьте заявку"
      subtitle="Оставьте заявку и мы с вами свяжемся"
      baseWidth={540}
      imgSrc=""
      onClose={onClose}
    >
      <form
        className={cls.form}
        onSubmit={formik.handleSubmit}
        noValidate
      >
        <Input
          name="name"
          type="text"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="Имя"
          error={formik.errors.name}
          id="name"
        />
        <Input
          name="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
          label="Email"
          placeholder="E-mail"
          id="email"
        />
        <Input
          name="phone"
          type="text"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.phone}
          placeholder="Телефон"
          id="tel"
        />
        <div className={cls.controls}>
          <button className="btn-primary">Оставить заявку</button>
        </div>
      </form>
    </Modal>
  );
};

export { OrderModal };
