import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Portfolio from './pages/Portfolio';
import About from './pages/About';
import VacancyPage from './pages/Vacancy';
import Service from './pages/Service';
import Main from './pages/Main/Main';
import ServiceDetailPage from './pages/Service/[pid]';
import './reset.css';
import VacancyManagerPage from './pages/Vacancy/manager';

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/portfolio"
          element={<Portfolio />}
        />
        <Route
          path="/about"
          element={<About />}
        />
        <Route
          exact
          path="/vacancy"
          element={<VacancyPage />}
        />
        <Route
          path="/vacancy/manager"
          element={<VacancyManagerPage />}
        />
        <Route
          exact
          path="/services"
          element={<Service />}
        />
        <Route
          path="/services/:pid"
          element={<ServiceDetailPage />}
        />
        <Route
          exact
          path="/"
          element={<Main />}
        />
      </Routes>
    </Router>
  );
}

export default App;
