import { Link } from 'react-router-dom';
import { links } from '../data/links';
import { useEffect, useState } from 'react';
import { OrderModal } from './OrderModal/OrderModal';
import { useLockScroll } from '../hooks/useLockScroll';

const Footer = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { lock, unlock } = useLockScroll();
  useEffect(() => {
    if (isModalOpen) {
      lock();
    } else {
      unlock();
    }
  }, [isModalOpen]);

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__left">
          <Link
            to="/"
            className="footer__logo"
          >
            <img
              src="/img/logo.svg"
              alt=""
            />
          </Link>
          <ul className="footer__left-menu">
            {links.map((link) => {
              return (
                <li
                  className="menu-modal__link"
                  key={link.label}
                >
                  <Link to={link.href}>{link.label}</Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="footer__right">
          <a
            href="tel:+74952408156"
            className="footer__right-item footer__phone"
          >
            + 7(495)240-81-56
          </a>
          <a
            href="mailto:manager@visint.online"
            className="footer__right-item footer__mail"
          >
            manager@visint.online
          </a>
        </div>
        <button
          className="header__order-btn"
          onClick={() => setModalOpen(true)}
        >
          Заказать
        </button>
      </div>
      {isModalOpen && (
        <OrderModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </footer>
  );
};

export default Footer;
