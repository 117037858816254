import cls from './Input.module.css';

const Input = ({
  variant,
  label,
  hint,
  error,
  id,
  className,
  hintClass,
  withLabel = true,
  value,
  onChange,
  size = 'md',
  ...other
}) => {
  const onlyNumbers = variant === 'number';

  const onKeyPress = (event) => {
    const { which, keyCode } = event;
    const ASCIICode = which || keyCode;
    if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
      event.preventDefault();
    }
  };

  const onChangeValue = (event) => {
    event.target.value = onlyNumbers
      ? event.target.value.replace(/[^0-9.]/g, '')
      : event.target.value;

    onChange?.(event);
  };

  const inputValue = onlyNumbers ? numberSpacing(value) : value;

  return (
    <div className={[cls.wrap, className, size, error ? error : ''].join(' ')}>
      <input
        id={id}
        className={cls.input}
        value={value}
        onKeyPress={onlyNumbers ? onKeyPress : other.onKeyPress}
        onChange={onChangeValue}
        {...other}
      />
      {(error || hint) && <span className={[cls.hint, hintClass].join(' ')}>{error || hint}</span>}
    </div>
  );
};

export default Input;
