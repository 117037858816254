import { Link } from 'react-router-dom';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { services } from '../../data/services';
import '../../App.css';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Helmet } from 'react-helmet';

const Service = () => {
  return (
    <>
      <Header />
      <main>
        <Helmet>
          <title>Услуги</title>
        </Helmet>
        <div className="container">
          <Breadcrumbs
            last="Услуги"
            list={[
              {
                href: '/',
                label: 'Главная',
              },
            ]}
          />
        </div>
        <div className="container">
          <h1 className="h1">Услуги</h1>
        </div>
        <section className="services">
          <div className="container">
            <ul className="services__list">
              {services.map((service) => {
                return (
                  <li key={service.id}>
                    <Link to={service.href}>{service.title}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default Service;
