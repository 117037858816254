import VacancyItem from '../../components/VacancyItem';
import { vacancy } from '../../data/vacancy';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import '../../App.css';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Helmet } from 'react-helmet';
const VacancyPage = () => {
  return (
    <>
      <Header />
      <main>
        <Helmet>
          <title>Вакансии</title>
        </Helmet>
        <div className="section section-h">
          <div className="container">
            <Breadcrumbs
              last="Вакансии"
              list={[
                {
                  href: '/',
                  label: 'Главная',
                },
              ]}
            />
            <h1 className="h1">Вакансии</h1>
            <div className="vacancy">
              {vacancy.map((item) => {
                return (
                  <VacancyItem
                    key={item.id}
                    item={item}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default VacancyPage;
