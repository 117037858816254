import { useEffect, useRef } from 'react';

const useLockScroll = () => {
  const prevOverflow = useRef();

  useEffect(() => {
    return unlock;
  }, []);

  const handleTouch = (event) => event.preventDefault();

  const unlock = () => {
    document.body.style.overflow = prevOverflow.current;
    document.body.removeEventListener('touchmove', handleTouch);
    document.body.style.pointerEvents = 'unset';
  };

  const lock = () => {
    prevOverflow.current = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    // document.body.style.pointerEvents = 'none';
    document.body.addEventListener('touchmove', handleTouch);
  };

  return {
    lock,
    unlock,
  };
};

export { useLockScroll };
