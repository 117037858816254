export const services = [
  {
    id: 1,
    href: '/services/1',
    title: 'Виртуализация рабочих мест (RDS/VDI)',
  },
  {
    id: 2,
    href: '/services/2',
    title: 'Система управления печатью',
  },
  {
    id: 3,
    href: '/services/3',
    title: 'Мультимедийные системы',
  },
  {
    id: 4,
    href: '/services/4',
    title: 'СХД (Системы хранения данных)',
  },
  {
    id: 5,
    href: '/services/5',
    title: 'Структурированная кабельная система',
  },
];
