import Header from '../../components/Header';
import '../../style.css';
import '../../main.css';
import AquariusLogo from '../../assets/icons/Mask group.svg';
import IrbisLogo from '../../assets/icons/irbis.svg';
import Ikar from '../../assets/icons/ikar.svg';
import IruLogo from '../../assets/icons/iru.svg';
import { Tooltip } from 'react-tooltip';

const Main = () => {
  return (
    <div className="main-wrapper">
      <Header isMain />
      <main className="main">
        <section className="section-main">
          <div className="container">
            <h1 className="title">
              Реализация IT проектов любой сложности под ключ для государственных предприятий, а так
              же для частного бизнеса.
            </h1>
            <div className="section-main__block">
              <div className="section-main__item">
                <img
                  src="/img/img1.svg"
                  alt="img1"
                />
                <span>Сервера</span>
              </div>
              <div className="section-main__item">
                <img
                  src="/img/img2.svg"
                  alt="img2"
                />
                <span>Мультимедиа</span>
              </div>
              <div className="section-main__item">
                <img
                  src="/img/img3.svg"
                  alt="img3"
                />
                <span>Коммуникации</span>
              </div>
              <div className="section-main__item">
                <img
                  src="/img/img4.svg"
                  alt="img4"
                />
                <span>Инженерные системы</span>
              </div>
              <div className="section-main__item">
                <img
                  src="/img/img5.svg"
                  alt="img5"
                />
                <span>Программное обеспечение</span>
              </div>
            </div>
          </div>
        </section>
        <section className="makers">
          <div className="container">
            <div className="makers__inner">
              <h2>Производители</h2>

              <div className="makers__text">
                Висмут интегра, мы создаем, помогаем нашим клиентам создать  надежные и эффективные
                информационные инфраструктуры. Наша компания ставит своей целью удовлетворение
                потребностей клиентов в качественном оборудовании и предоставление высококлассного
                сервиса Если вы ищете надежного партнера для поставки оборудования, который
                предлагает высокое качество, надежность и отличный сервис, обратитесь к нам. Мы
                готовы помочь вам создать инновационную и эффективную информационную инфраструктуру
                для развития вашего бизнеса.
              </div>
              <ul className="makers__list">
                <li>
                  <button id="tooltip1">
                    <img src={Ikar} />
                  </button>
                </li>
                <li>
                  <button id="tooltip2">
                    <img src={IruLogo} />
                  </button>
                </li>
                <li>
                  <button id="tooltip3">
                    <img src={IrbisLogo} />
                  </button>
                </li>
                <li>
                  <button id="tooltip4">
                    <img src={AquariusLogo} />
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <Tooltip
            anchorSelect="#tooltip1"
            className="tooltip"
            content={`
            ООО «ИКАР» — федеральная российская компания по производству профессиональных дисплеев и видеостен. Наши производственные площадки находятся в Оренбурге и Москве. Также существует региональная сеть представительств в Новосибирске, Казани, Санкт-Петербурге и Краснодаре. В наш функционал входит разработка специализированного программного обеспечения, проектирование, разработка и изготовление LCD панелей и светодиодных LED экранов.`}
          />
          <Tooltip
            anchorSelect="#tooltip3"
            className="tooltip"
            content={`
            IRBIS — торговая марка, под которой выпускаются ноутбуки, планшеты, смартфоны, ПК, моноблоки и другая компьютерная техника. С 2014 году официальным дистрибьютором производителя стала компания Treolan, входящая в группу компаний ЛАНИТ. С помощью экспертной и маркетинговой поддержки дистрибьютора IRBIS полностью изменил линейку продукции, фирменный стиль, создал новые направления бизнеса. В этом же году IRBIS стал первым российским производителем, поставляющим в Россию бюджетные, и при этом качественные Windows-устройства 2 в 1.
По данным IDC в 2016 году бренд стал лидером по поставкам планшетов и устройств 2 в 1 в России.
В 2014-2015 гг. IRBIS несколько раз становился лидером продаж в сегменте планшетов и смартфонов в федеральных торговых сетях. По версии исследовательской компании GFK в 2016 и 2017 гг. производитель занял первое место среди основных игроков рынка планшетных ПК в России, а в 2018-м стал лидером по поставкам в сегменте медиабуков в РФ.
            `}
          />
          <Tooltip
            anchorSelect="#tooltip2"
            className="tooltip"
            content={`
            iRU – российский производитель компьютерной техники, основанный в 2002 году, крупнейшим широкопрофильным дистрибьютором России. iRU входит в топ-3 российских производителей ПК наряду с компаниями мирового масштаба.
            Ассортимент продукции iRU включает в себя товары для домашних пользователей, решения для малого, среднего бизнеса и крупных корпораций: компьютеры, моноблоки, ноутбуки, графические и рабочие станции, серверы любого уровня сложности, системы хранения данных, программно-аппаратные комплексы и телекоммуникационное оборудование.            `}
          />
          <Tooltip
            anchorSelect="#tooltip4"
            className="tooltip"
            content={`
            Компания «Аквариус» — крупнейший российский разработчик, производитель и поставщик компьютерной техники и ИТ-решений, системообразующее предприятие радиоэлектронной промышленности. Обеспечивает полный производственный цикл выпуска высокотехнологичного оборудования, включая печать плат, поверхностный монтаж компонентов и сборку изделий. Реализует ИТ-проекты федерального и регионального масштаба.
            `}
          />
        </section>
      </main>
    </div>
  );
};

export default Main;
