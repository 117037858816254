export const services = [
  {
    id: 1,
    title: 'Виртуализация рабочих мест (RDS/VDI)',
    description: `
        Услуга виртуализации рабочих мест предоставляет компаниям и организациям передовое решение для оптимизации инфраструктуры и управления компьютерными рабочими станциями. Вместо традиционных физических компьютеров, виртуализация позволяет создавать виртуальные машины (ВМ) на серверах и предоставлять доступ к этим ВМ удаленно для пользователей.
        `,
    charact: [
      {
        title: 'Централизованное управление',
        description: `
                Все ВМ и приложения хранятся и управляются на центральном сервере, что облегчает администрирование, мониторинг и обновление системы.
                `,
      },
      {
        title: 'Экономия ресурсов',
        description: `
                Виртуализация позволяет эффективнее использовать аппаратные ресурсы, так как несколько ВМ могут работать на одном сервере, снижая потребление энергии и стоимость оборудования.
                `,
      },
      {
        title: 'Безопасность',
        description: `
                Виртуализация обеспечивает высокий уровень безопасности, так как каждая ВМ изолирована от других, минимизируя риски утечки данных или вирусных атак.
                `,
      },
      {
        title: 'Гибкий доступ',
        description: `
                Пользователи могут получать доступ к своим рабочим станциям через любое устройство с интернет-подключением, обеспечивая удобство работы из любой точки мира.
                `,
      },
      {
        title: 'Увеличение масштабируемости',
        description: `
                Добавление новых ВМ или увеличение ресурсов существующих происходит легко и быстро, что упрощает масштабирование системы с ростом бизнеса.  
                `,
      },
      {
        title: 'Совместимость',
        description: `
                Виртуализация позволяет использовать различные операционные системы на одном сервере, обеспечивая гибкость и совместимость с различными приложениями.
                `,
      },
      {
        title: 'Централизованное резервное копирование',
        description: `
                Виртуальные рабочие станции могут быть резервно скопированы, что обеспечивает защиту данных и быстрое восстановление в случае сбоев.
                `,
      },
      {
        title: 'Удобство обновлений и патчей',
        description: `
                Обновления операционных систем и программного обеспечения могут быть легко установлены на центральном сервере и автоматически распространены на все ВМ.
                `,
      },
    ],
    text: `
        Услуга виртуализации рабочих мест обеспечивает более эффективное и безопасное управление IT-инфраструктурой, повышает гибкость и мобильность сотрудников, а также сокращает затраты на оборудование и его обслуживание. Это инновационное решение для современных организаций, которые стремятся повысить эффективность и оптимизировать рабочий процесс.
        `,
  },
  {
    id: 2,
    title: 'Система управления печатью',
    description: `
        Система управления печатью (СУП) представляет собой комплексное решение для эффективного управления и контроля печатными процессами в рамках организации. Эта услуга позволяет компаниям оптимизировать расходы на печать, повысить безопасность документов и улучшить общую производительность.
        `,
    charact: [
      {
        title: 'Централизованное управление',
        description: `
                Система управления печатью предоставляет централизованный контроль над всеми устройствами печати в организации. Администраторы могут мониторить, управлять и настраивать параметры печати со своего рабочего места.
                `,
      },
      {
        title: 'Безопасность печати',
        description: `
                Услуга обеспечивает повышенную безопасность документов путем реализации авторизации пользователя перед печатью. Пользователи должны быть аутентифицированы перед тем, как получить доступ к устройствам печати, что помогает предотвратить несанкционированный доступ к конфиденциальным документам.
                `,
      },
      {
        title: 'Оптимизация расходов',
        description: `
                Система управления печатью позволяет контролировать объемы печати и расходы на чернила, тонер и бумагу. Это помогает сократить ненужные расходы и оптимизировать бюджеты на печать.
                `,
      },
      {
        title: 'Распределение заданий печати',
        description: `
                СУП позволяет перенаправлять задания печати на наиболее доступные и свободные принтеры, что улучшает производительность и сокращает ожидание печати.
                `,
      },
      {
        title: 'Автоматическая отчетность',
        description: `
                Система предоставляет детальные отчеты о печатной активности пользователей и устройств, что помогает анализировать использование печатных ресурсов и принимать меры для оптимизации процесса.
                `,
      },
      {
        title: 'Экологические инициативы',
        description: `
                Внедрение СУП способствует уменьшению использования бумаги и расхода энергии на печать, что помогает организациям принимать активное участие в устойчивых практиках.
                `,
      },
      {
        title: 'Удобство и гибкость',
        description: `
                Система управления печатью предоставляет интуитивный интерфейс и возможности для индивидуальной настройки под требования каждой компании.
                `,
      },
      {
        title: 'Техническая поддержка',
        description: `
                Поставщики услуг СУП обеспечивают техническую поддержку и регулярные обновления программного обеспечения, что гарантирует стабильную и безопасную работу системы.
                `,
      },
    ],
    text: `
        Система управления печатью представляет собой эффективное решение для компаний, стремящихся оптимизировать процессы печати, снизить расходы, улучшить безопасность и внедрить более устойчивые и экологически ответственные подходы к печати документов.
        `,
  },
  {
    id: 3,
    title: 'Мультимедийные системы',
    description: `
        Мультимедийные системы - это комплексная услуга, предоставляемая компаниями и организациями для создания, интеграции и управления современными мультимедийными решениями. Цель этой услуги - обеспечить клиентам передовые технологии для эффективного использования мультимедийных средств в различных областях, таких как образование, бизнес, развлечение, маркетинг и презентации.
        `,
    charact: [
      {
        title: 'Проектирование и установка мультимедийных систем',
        description: `
                Специалисты помогают клиентам разработать оптимальные мультимедийные решения, учитывая их уникальные потребности и бюджет. После проектирования происходит профессиональная установка оборудования и программного обеспечения.
                `,
      },
      {
        title: 'Интеграция мультимедийных технологий',
        description: `
                Услуга предоставляет интеграцию различных мультимедийных устройств и систем, таких как проекторы, интерактивные доски, аудио- и видеооборудование, конференц-системы и другие, в одну совместимую и гармонично работающую систему.
                `,
      },
      {
        title: 'Обучение и консультации',
        description: `
                Команда экспертов обучает сотрудников клиента, чтобы они эффективно использовали мультимедийное оборудование и программное обеспечение. Также оказывается консультационная поддержка для решения технических вопросов и оптимизации использования системы.
                `,
      },
      {
        title: 'Комплексные решения для образования',
        description: `
                Мультимедийные системы включают в себя образовательные технологии для школ, университетов и образовательных центров, такие как интерактивные учебные доски, компьютерные классы, системы видеоконференций для дистанционного обучения и другие инструменты.
                `,
      },
      {
        title: 'Конференц-системы',
        description: `
                Предоставляется оборудование и программное обеспечение для организации успешных и продуктивных конференций и семинаров с поддержкой аудио- и видеообмена, интерактивных презентаций и удаленных участников.
                `,
      },
      {
        title: 'Презентационные решения',
        description: `
                Мультимедийные системы помогают создавать эффективные и привлекательные презентации с помощью проекторов, аудиоэффектов и специальных программ для управления контентом.
                `,
      },
      {
        title: 'Развлекательные системы',
        description: `
                Для развлекательных заведений предоставляются мультимедийные решения для создания атмосферы и привлечения посетителей с помощью интерактивных устройств, медиавозможностей и других технологий.
                `,
      },
      {
        title: 'Техническая поддержка',
        description: `
                Компании предоставляют техническую поддержку и обслуживание мультимедийных систем, чтобы обеспечить их стабильную и бесперебойную работу.
                `,
      },
    ],
    text: `
        Услуга "Мультимедийные системы" предоставляет организациям возможность использовать передовые технологии для создания удивительных мультимедийных впечатлений, повышения эффективности работы и улучшения образовательного или развлекательного опыта для клиентов и сотрудников.
        `,
  },
  {
    id: 4,
    title: 'СХД (Системы хранения данных)',
    description: `
        Системы хранения данных (СХД) - это специализированное решение, предоставляемое компаниями и организациями, для эффективного и безопасного хранения, управления и обработки больших объемов информации. СХД являются критической частью IT-инфраструктуры компаний и предназначены для обеспечения надежной и высокопроизводительной работы с данными.
        `,
    charact: [
      {
        title: 'Хранение больших объемов данных',
        description: `
                СХД предоставляют высокую емкость хранения данных, что позволяет организациям сохранять большие объемы информации, включая файлы, базы данных, виртуальные машины и другие данные.
                `,
      },
      {
        title: 'Оптимизация производительности',
        description: `
                СХД обеспечивают быстрый доступ к данным и высокую производительность ввода-вывода (I/O), что особенно важно для приложений с высокими требованиями к скорости работы.
                `,
      },
      {
        title: 'Масштабируемость',
        description: `
                СХД могут быть легко масштабированы в зависимости от потребностей компании. Это позволяет быстро адаптироваться к росту объемов данных и изменяющимся требованиям.
                `,
      },
      {
        title: 'Высокая надежность',
        description: `
                СХД предоставляют уровень отказоустойчивости и безопасности данных, что обеспечивает сохранность информации даже в случае сбоев или аварий.
                `,
      },
      {
        title: 'Виртуализация и управление',
        description: `
                Многие СХД поддерживают виртуализацию и управление хранилищем, что позволяет оптимизировать использование ресурсов и облегчает администрирование.
                `,
      },
      {
        title: 'Резервное копирование и восстановление',
        description: `
                СХД обеспечивают возможность резервного копирования данных и быстрого восстановления в случае потери или повреждения информации.
                `,
      },
      {
        title: 'Защита данных',
        description: `
                СХД предоставляют механизмы шифрования и защиты данных, что помогает предотвращать несанкционированный доступ и утечки информации.
                `,
      },
      {
        title: 'Техническая поддержка',
        description: `
                Поставщики СХД предоставляют техническую поддержку и обслуживание, гарантируя стабильную работу системы.
                `,
      },
    ],
    text: `
        Услуги СХД являются неотъемлемой частью для организаций, которые сталкиваются с постоянно растущим объемом данных и нуждаются в надежной и высокопроизводительной системе хранения информации. СХД помогают повысить эффективность работы с данными, обеспечить безопасность информации и поддерживать стабильность бизнес-процессов.
        `,
  },
  {
    id: 5,
    title: 'Структурированная кабельная система',
    description: `
        Структурированная кабельная система (СКС) - это профессиональное решение, предоставляемое компаниями и специализированными организациями для создания сетевой инфраструктуры, позволяющей организациям эффективно передавать данные, голосовую информацию и видеосигналы внутри зданий или кампусов.
        `,
    charact: [
      {
        title: 'Структурированный подход',
        description: `
                СКС основана на структурированном подходе, который предполагает использование стандартных кабельных типов, коннекторов и оборудования. Это обеспечивает гибкость и удобство в масштабировании, изменении и модернизации сети.
                `,
      },
      {
        title: 'Оптимизация сетевой инфраструктуры',
        description: `
                СКС обеспечивает организацию четкой и оптимальной кабельной инфраструктуры, что упрощает управление и обслуживание сети, а также снижает затраты на проведение и обслуживание кабельных линий.
                `,
      },
      {
        title: 'Гибкость',
        description: `
                СКС позволяет подключать различные устройства и сетевое оборудование, такие как компьютеры, принтеры, VoIP-телефоны, камеры видеонаблюдения, Wi-Fi точки доступа и другое, к одной централизованной сети.
                `,
      },
      {
        title: 'Повышение производительности',
        description: `
                Структурированная кабельная система обеспечивает высокую скорость передачи данных, что способствует повышению производительности сети и улучшению работы с приложениями и сервисами.
                `,
      },
      {
        title: 'Гарантия качества сигнала',
        description: `
                СКС строго соблюдает стандарты и требования к кабельным линиям и соединениям, что гарантирует стабильную и надежную передачу данных, минимизируя потери сигнала и помехи.
                `,
      },
      {
        title: 'Широкий выбор кабельных типов',
        description: `
                СКС предоставляет разнообразие типов кабелей, таких как витая пара, оптоволокно и коаксиальный кабель, что позволяет адаптировать сеть под различные требования.
                `,
      },
      {
        title: 'Соблюдение стандартов',
        description: `
                Структурированная кабельная система соответствует международным стандартам, таким как ANSI/TIA/EIA, ISO/IEC и другим, что обеспечивает совместимость и интероперабельность с оборудованием различных производителей.
                `,
      },
      {
        title: 'Техническая поддержка и гарантии',
        description: `
                Поставщики услуг СКС обеспечивают профессиональную установку и настройку сети, а также предоставляют гарантии на оборудование и выполненные работы.
                `,
      },
    ],
    text: `
        Услуга Структурированной кабельной системы является ключевым элементом для создания надежной и эффективной сетевой инфраструктуры. СКС помогает организациям обеспечить высокоскоростное и стабильное соединение между устройствами, повышает производительность и облегчает управление сетью, что способствует повышению эффективности и конкурентоспособности компании.
        `,
  },
];
