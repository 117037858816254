import React from 'react';
import { Link } from 'react-router-dom';

const VacancyItem = ({ item }) => {
  return (
    <div className="vacancy__item">
      <div className="vacancy__item-top">
        <div className="left">{item.position}</div>
        <div className="right">{item.price}</div>
      </div>
      <div className="bricks">
        {item.bricks.map((brick, index) => {
          return (
            <div
              className="bricks_item"
              key={index}
            >
              {brick}
            </div>
          );
        })}
      </div>
      <div className="vacancy__item-bottom">
        <div className="desc">{item.description}</div>
        <Link
          to={item.href}
          className="vacancy_more"
        >
          Подробнее
        </Link>
      </div>
    </div>
  );
};

export default VacancyItem;
