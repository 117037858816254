import { Link } from 'react-router-dom';

const Breadcrumbs = ({ list, last }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {list.map((item) => {
          return (
            <li
              className="breadcrumb-item"
              key={item.label}
            >
              <Link to={item.href}>{item.label}</Link>
            </li>
          );
        })}
        <li
          className="breadcrumb-item active"
          aria-current="page"
        >
          {last}
        </li>
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
