import { useFormik } from 'formik';

const useAppForm = (initialValues, onSubmit, validate, ...params) => {
  return useFormik({
    initialValues,
    onSubmit,
    validateOnChange: false,
    validate,
    ...params,
  });
};

export { useAppForm };
