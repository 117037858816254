export const links = [
  {
    label: 'О компании',
    href: '/about',
  },
  {
    label: 'Услуги',
    href: '/services',
  },
  {
    label: 'Вакансии',
    href: '/vacancy',
  },
  {
    label: 'Реализованные проекты',
    href: '/portfolio',
  },
];
