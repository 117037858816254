import { links } from '../data/links';
import { Link } from 'react-router-dom';

const MenuModal = ({ isOpen, onClose }) => {
  return (
    <div
      className={['menu-modal', isOpen ? 'active' : ''].join(' ')}
      id="mobile-menu-modal"
    >
      <div className="menu-modal__header">
        <span>Меню</span>
        <span
          className="close"
          id="close-menu-modal"
          onClick={onClose}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.7708 0.229216C12.0764 0.534837 12.0764 1.03035 11.7708 1.33597L7.10675 6L11.7708 10.664C12.0764 10.9697 12.0764 11.4652 11.7708 11.7708C11.4652 12.0764 10.9697 12.0764 10.664 11.7708L6 7.10675L1.33597 11.7708C1.03035 12.0764 0.534837 12.0764 0.229216 11.7708C-0.0764051 11.4652 -0.076405 10.9697 0.229216 10.664L4.89325 6L0.229216 1.33597C-0.0764056 1.03035 -0.0764051 0.534837 0.229216 0.229216C0.534837 -0.0764054 1.03035 -0.0764052 1.33597 0.229216L6 4.89325L10.664 0.229216C10.9697 -0.0764052 11.4652 -0.0764054 11.7708 0.229216Z"
              fill="#9B9B9B"
            />
          </svg>
        </span>
      </div>
      <ul className="menu-modal__list">
        {links.map((link) => {
          return (
            <li
              key={link.label}
              className="menu-modal__link"
            >
              <Link to={link.href}>{link.label}</Link>
            </li>
          );
        })}
        <div className="menu-modal__contact">
          <a
            href="tel:+74952408156"
            className="menu-modal__contact-item"
          >
            + 7(495)240-81-56
          </a>
          <a
            href="mailto:manager@visint.online"
            className="menu-modal__contact-item"
          >
            manager@visint.online
          </a>
        </div>
      </ul>
    </div>
  );
};

export default MenuModal;
