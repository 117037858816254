import VacancyItem from '../../components/VacancyItem';
import { vacancy } from '../../data/vacancy';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import '../../App.css';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Helmet } from 'react-helmet';

const VacancyManagerPage = () => {
  return (
    <>
      <Header />
      <main>
        <Helmet>
          <title>Офис-менеджер, секретарь Висмут</title>
        </Helmet>
        <div className="section section-h">
          <div className="container">
            <Breadcrumbs
              last="Вакансии"
              list={[
                {
                  href: '/',
                  label: 'Главная',
                },
              ]}
            />
            <h1 className="h1">Офис-менеджер, секретарь</h1>
            <div className="vacancy-page">
              <div className="bricks">
                <div className="bricks_item">г. Москва</div>
                <div className="bricks_item">Без опыта</div>
                <div className="bricks_item">Полная занятость</div>
              </div>
              <div className="vacancy-page-body">
                <p className="vacancy-page-text">
                  <span>В обязанности входит:</span>
                  - Ведение документооборота: составление различных форм документации, подготовка
                  писем, заполнение договоров, кадровая документация, контроль оригиналов
                  закрывающих документов, ведение реестров и архива документов; <br />
                  - Работа в системе CRM, выполнение поставленных задач;
                  <br />
                  - Взаимодействие с курьерскими службами, организация доставок документов и
                  оборудования;
                  <br />
                  - Прием и распределение звонков;
                  <br />
                  - Встреча гостей чай/кофе;
                  <br />
                  - Всесторонняя поддержка руководителя и учредителей компании по любым вопросам,
                  выполнение личных и конфиденциальных поручений;
                  <br />
                  - Организация деловых и личных мероприятий;
                  <br />
                  - Доступность на почте и мобильном телефоне 24/7;
                  <br />
                  - Ведение деловой переписки и телефонных переговоров;
                  <br />- Контроль за порядком и чистотой в офисе компании.
                </p>
                <p className="vacancy-page-text">
                  <span>Требования:</span>
                  - Высшее образование; <br />
                  - Уверенный пользователь ПК;
                  <br />
                  - Высокий уровень коммуникативных навыков;
                  <br />
                  - Умение работать с конфиденциальной информацией;
                  <br />
                  - Готовность работать в динамичном режиме, желание развиваться и обучаться;
                  <br />
                  - Умение быстро ориентироваться в ситуации, находить решение нестандартных задач;
                  <br />
                  - Умение работать с большим объемом информации;
                  <br />- Самостоятельность, активность, организованность, высокая ответственность,
                  умение работать на результат, стрессоустойчивость, грамотная письменная и устная
                  речь.
                </p>
                <p className="vacancy-page-text">
                  <span>Условия:</span>
                  - Трудойстройство по ТК РФ.;
                  <br />
                  - Заработная плата белая (по результатам собеседования);
                  <br />
                  - Работа в молодой, активно развивающейся компании;
                  <br />
                  - Комфортный офис и рабочее место;
                  <br />
                  - Испытательный срок 3 месяца.
                  <br />- График работы 5/2 с 9.00 до 18.00.
                </p>
                <button className="btn btn-main">
                  <sapn>Откликнуться на вакансию</sapn>
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default VacancyManagerPage;
