export const vacancy = [
  {
    id: 1,
    position: 'Офис-менеджер, секретарь',
    salary: 'от 50 000 ₽',
    bricks: ['г. Москва', 'Без опыта', 'Полная занятость'],
    description: `
        В связи с расширением компании ищем сотрудников на вакансию «Офис-менеджер, секретарь»
        Приглашаем активных и общительных людей, готовых к интересной работе и желающих профессионально расти и развиваться.`,
    href: '/vacancy/manager',
  },
];
